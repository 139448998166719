#UserAgent
_ua = do ->#{{{
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and !ltIE6
  IE8 = IE and ltIE8 and !ltIE7 and !ltIE6
  IE9 = IE and ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  IE10 = IE and !ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  Webkit = !document.uniqueID and !window.opera and !window.sidebar and !window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }
#}}}

#URL
url = do ->#{{{
  href = location.href.split '/'

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/work\/[^\/]+\/.*$/

  for val , i in href
    if val == '' or i == href.length - 1 and val.indexOf '.'
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j == 0
      path += '//'

    else
      path += '/'

  return path
#}}}
#
#スムーススクロール関数モーション定義
jQuery.extend(#{{{
  jQuery.easing,
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
)
#}}}

$ ->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  #フッター高さ取得
  footerHeight = # {{{
    ->
      add = 0
      height = $('#footer').outerHeight()
      $('#content').css('padding-bottom',height+add)
      $('#footer').css('height',height)
      return

  $(window).on('resize load'
    ->
      footerHeight()
      return
  )
  # }}}

  # アンカーリンク スムーススクロール
  $('a[href^=#]').click(#{{{
    (e)->
      $(
        if (navigator.userAgent.indexOf('Opera') isnt -1)
        then (
          if document.compatMode is 'BackCompat'
          then 'body'
          else 'html'
        )
        else 'html,body'
      ).animate(
        scrollTop:$($(this).attr('href')).offset().top - 80
      ,
        easing:'easeInOutCirc',
        duration:500
      )

      e.preventDefault()
      return
  )#}}}

  $('.sd-pagetop a[href^=#]').on('click'
    (e)->
      $(
        if (navigator.userAgent.indexOf('Opera') isnt -1)
        then (
          if document.compatMode is 'BackCompat'
          then 'body'
          else 'html'
        )
        else 'html,body'
      ).animate(
        scrollTop:$($(this).attr('href')).offset().top - 20
      ,
        easing:'easeInOutCirc',
        duration:500
      )

      e.preventDefault()
      return
  )

  if $class.indexOf("form") is -1
    #問い合わせライトナビ
    $ ->
      percent = 0.2
      rightNav = $(".sidebar")
      rightNav.hide()
      $(window).scroll ->
        if $(this).scrollTop() > $("#container").height() * percent
          rightNav.fadeIn()
        else
          rightNav.fadeOut()
        return

      return

    # 固定コンテンツ制御
    fixedContents =
      ()->
        mainHeight = $('#main').height()
        subHeight = $('#sub').height()

        if subHeight < mainHeight
          $('#sub').css('min-height', $('#main').outerHeight(true))

          mainContentsHeight = $('#main').outerHeight(true)
          subContentsHeight = $('#sub').outerHeight(true)
          windowHeight = $(window).height()
          # 30 = メニュー下マージン
          # adjustMargin = $('.gnavi').outerHeight() + 30
          adjustMargin = $('#gnavi').outerHeight() + 10

          $fixContents = $('.s-fix-contents')
          fixContentsHeight = $('.s-fix-contents').outerHeight(true)
          fixContentsPos = $fixContents.offset().top - adjustMargin

          fixFlg = false
          bottomFlg = false

          $(window).scroll(
            ()->
              # メインコンテンツの高さが変わった時時再度高さ適応
              currentSubHeight = $('#sub').outerHeight(true)
              currentMainContentsHeight = $('#main').outerHeight(true)

              if currentMainContentsHeight > mainContentsHeight
                $('#sub').css('min-height', currentMainContentsHeight)

              currentSubContentsHeight = $('#sub').outerHeight(true)

              # メニュー開閉等で位置が変わった時再計算
              currentFixContentsPos = $('.s-fix-contents').offset().top - adjustMargin

              if $('.s-fix-contents').not('.is-fixed,.is-bottom').length isnt 0
                #currentFixContentsPos = $('.s-fix-contents').not('.is-fixed,.is-bottom').offset().top - adjustMargin

                if currentFixContentsPos > fixContentsPos
                  fixContentsPos = currentFixContentsPos

              # サブコンテンツの方が高さが低い時のみ実行
              if currentSubContentsHeight <= currentMainContentsHeight
                scrollPos = $(window).scrollTop()
                fixContentsBottomPos = $('#container').height() - (scrollPos + windowHeight) - $('#footer').outerHeight() - ((adjustMargin + fixContentsHeight) - windowHeight)

                if scrollPos > fixContentsPos
                  if not fixFlg and not bottomFlg
                    $fixContents.addClass('is-fixed')
                    fixFlg = true
                else
                  $fixContents.removeClass('is-fixed')
                  if fixFlg
                    fixFlg = false

                if fixContentsBottomPos < 0
                  $fixContents.removeClass('is-fixed')
                  if fixFlg
                    fixFlg = false

                    if not bottomFlg
                      $fixContents.addClass('is-bottom')
                      bottomFlg = true
                else
                  if bottomFlg
                    $fixContents.removeClass('is-bottom').addClass('is-fixed')
                    bottomFlg = false
          )

    if $('.s-fix-contents').length isnt -1
      $(window).on('load'
        ()->
          fixedContents()
      )

    #グロナビ追従
    headerinnerHeight = $("#header .header-inner").height() + 10
    $(window).load ->
      headerHeight = $("#header").height()

    nav = $("#gnavi")
    navHeight = nav.height()
    showFlag = false
    $(window).scroll ->
      nowPos = $(this).scrollTop()
      if nowPos > headerinnerHeight
        if showFlag is false
          showFlag = true
          nav.css
            width: "100%"
            display: "block"
            position: "fixed"
            top: "0"
            left: "0"
            zIndex: "1000"
          $(".mainimg").css marginTop: "0"
      else if nowPos <= headerinnerHeight
        if showFlag
          showFlag = false
          nav.css position: "static"
          nav.removeAttr('style')
          $(".mainimg").removeAttr('style')
      return

  